import React, {useEffect, useState} from 'react'

import Emoji from '../../components/shared/emoji'
import ReceiptItem from '../../components/gallery/checkout/ReceiptItem'
import {formatPrice} from '../../components/shared/utils'
import updateAction from '../../components/gallery/checkout/updateAction'
import {useStateMachine} from 'little-state-machine'

const Success = () => {
  const {state, action} = useStateMachine(updateAction)
  const [details, setDetails] = useState({})
  // update litle-state-machine with country value so shipping calculation renders
  const [data, setData] = useState({})
  const [cart, setCart] = useState([])
  const [last4, setLast4] = useState()
  useEffect(() => {
    console.log(state.data)
    setData(state.data)
    setCart(state.data.Cart)
    setLast4(state.data.paymentMethod.card.last4)
    setDetails(state.data.details)
  }, [state.data])

  return (
    <div className="flex flex-col">
      <div className=" text-white  w-full  bg-gray tracking-widest">
        <div className="bg-gray pt-20 mx-8 mx-8 md:mx-10 text-4xl md:text-7xl pt-20 leading-header">
          Order Successful
        </div>
      </div>
      <div className="mx-8 md:mx-10 mt-10">
        <div className="text-xl md:text-2xl my-2 ">
          Hey {data.firstName}, <Emoji symbol="👋" />
        </div>
        <div className="md:text-xl font-bold text-lg my-2">
          Your purchase was successful!
          <Emoji symbol="  🎉" />
        </div>
        <div className="md:text-xl text-lg">your receipt was emailed to {data.email}</div>
        <div className="my-4 md:text-lg text-sm">
          If something about your order doesn't look right, don't hestitate to{' '}
          <a className="hover:underline italic " href="mailto:info@johndore.xyz">
            get in touch.
          </a>
        </div>
      </div>
      <div className="mx-8 md:mx-10 mt-10 leading-none z-20 text-2xl">Order Summary</div>
      <div className="border-b border-white"></div>
      <div className="m-10">
        {cart.map(([sku, quantity]) => {
          return (
            <>
              <div className="border-t border-b border-white my-4">
                <ReceiptItem details={data.details} sku={sku} quantity={quantity} />
              </div>
            </>
          )
        })}
        <div className="flex flex-row justify-between inline-block ">
          <div className="text-xs ">
            Shipping -<span className="font-mono mx-1 font-normal">{details.shippingCost}</span>
          </div>
        </div>

        {/* <div className="border-b my-1 border-white "></div> */}
        <div className="flex flex-row justify-between inline-block my-2">
          <div className=" text-sm font-bold">
            Total -
            <span className="font-bold text-sm mx-1">
              <div className="font-mono inline-block ">{details.totalCost}</div>
            </span>
          </div>
        </div>
        <div className="border-b  my-1 border-white "></div>

        <div className="font-mono">{}</div>
      </div>
      <div className="border-b border-white"></div>
      <div className="mx-8 md:mx-10 mt-10 leading-none z-20 text-2xl ">Shipping Address</div>
      <div className="border-b border-white"></div>
      <div className="mx-8 md:mx-10 my-10 font-medium ">
        <div>{data.address1}</div>
        <div>{data.address2}</div>
        <div>{data.country}</div>
        <div>{data.region}</div>
        <div>{data.postal_code}</div>
      </div>
      <div className="border-b border-white"></div>
      <div className="mx-8 md:mx-10 mt-10 leading-none z-20 text-2xl">Personal Details</div>
      <div className="border-b border-white"></div>
      <div className="mx-8 md:mx-10 my-10 font-medium">
        <div>{data.firstName + ' ' + data.lastName}</div>
        <div>{data.email}</div>
        <div className="my-2 font-mono">**** **** **** {last4}</div>
      </div>
      <div className="border-b border-white mb-10"></div>
    </div>
  )
}

export default Success
