import React, {useContext, useEffect, useState} from 'react'
import {StateMachineProvider, createStore} from 'little-state-machine'

import Address from './checkout/address'
import {CartContext} from '../components/gallery/cart/CartProvider'
import Details from './checkout/details'
import {DevTool} from 'little-state-machine-devtools'
import Payment from './checkout/payment'
import {Router} from '@reach/router'
import Success from './checkout/success'
import {loadStripe} from '@stripe/stripe-js'
import {navigate} from 'gatsby'
import {useLayoutValue} from '../components/ui/layoutProvider'

createStore({
  data: {},
})

const checkout = () => {
  const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY)
  const {cart, count, mode, total, toggle} = useContext(CartContext)
  const {state, dispatch} = useLayoutValue()

  useEffect(() => {
    count == 0 ? navigate('/cart') : navigate('/checkout/details')
    console.log(cart)
  }, [])

  return (
    <>
      {/* <CheckoutLayout> */}
      {/* store data with little-state-machine and use client side paths to allow for multi stage form  */}
      <StateMachineProvider>
        <DevTool />
        <Router>
          <Details path="/checkout/details" component={Details} />
          <Address path="/checkout/address" component={Address} />
          <Payment path="/checkout/payment" component={Payment} />
          <Success path="/checkout/success" component={Success} />
        </Router>
      </StateMachineProvider>
      {/* </CheckoutLayout> */}
    </>
  )
}
export default checkout
