import React, {useContext, useEffect, useState} from 'react'

import Img from 'gatsby-image'
import {Link} from 'gatsby'
import styled from 'styled-components'

const formatPrice = (amount, currency) => {
  let price = (amount / 100).toFixed(2)
  let numberFormat = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  })
  return numberFormat.format(price)
}

const ReceiptItem = ({sku, quantity, details}) => {
  return (
    <div className="flex flex-col   my-2 text-black justify-between font-sans " key={sku.id}>
      <div className="flex  w-full">
        <Link
          className=" self-start  w-1/2  md:w-1/12"
          to={`/gallery/${sku.product.name.toLowerCase()}`}>
          <Img
            className=" "
            fluid={sku.localFiles[0].childImageSharp.fluid}
            alt={sku.product.name}
          />
        </Link>
        <div className="flex flex-col w-full justify-between  font-medium  ">
          {/* <div>&times;{count}</div> */}
          <div>
            <Link to={`/gallery/${sku.product.name.toLowerCase()}`}>
              <div className="mx-4  text-bold">
                {sku.product.name} <span className="px-4 text-sm font-mono">&times;{quantity}</span>
              </div>
            </Link>
            <div className="mx-4 text-xs">{sku.product.description}</div>
            <div className="mx-4 text-xs">{details.serial}</div>
          </div>
          <div className="mx-4   font-mono text-bold text-sm leading-loose">
            {formatPrice(sku.price * quantity, sku.currency)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReceiptItem
